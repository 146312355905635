import { Avatar } from '@common/components/Avatar';
import './styles.scss';
import { icons } from '../../icons';
import { Button, Conditional } from 'iq-blueberry';
import { itensMenu, rightIcon, item } from './data';
import { RouteMap } from '@common/constants/RouteMap';
import { useLocation } from 'react-router-dom';
import { NewFeatureTag } from '@common/components/NewFeaturesTag';
import { AppReview } from '../AppReview';

interface MenuProps {
  name: string;
  showMenu: boolean;
  onClose: () => void;
  profileEdit: () => void;
  navClick: (RouteMap: RouteMap, title: string) => void;
}

export const Menu: React.FC<MenuProps> = ({
  name,
  showMenu,
  onClose,
  profileEdit,
  navClick,
}) => {
  const location = useLocation().pathname;

  function twoFirstNames(name: string) {
    const words = name.trim().split(/\s+/);
    if (words.length >= 2) {
      const ligature = ['da', 'das', 'de', 'di', 'dos', 'do', 'e'];
      if (ligature.includes(words[1].toLowerCase())) {
        words.splice(1, 1);
      }

      return words.slice(0, 2).join(' ');
    } else {
      return name;
    }
  }

  function firstName(name: string) {
    const splitName = name.split(' ');
    return `Olá, ${splitName[0]}`;
  }

  function close(e: EventTarget) {
    if (e == document.getElementById('menu-header')) onClose();
  }

  return (
    <div
      className={`menu-header ${showMenu ? 'menu-open' : 'menu-close'}`}
      id="menu-header"
      onClick={(e) => close(e.target)}
    >
      <div
        className={`menu-header__card ${showMenu ? 'card-open' : 'card-close'}`}
      >
        <div className="menu-header__header">
          <div className="menu-header__header__left">
            <div className="menu-header__avatar">
              <Avatar
                username={twoFirstNames(name)}
                className="menu-header__avatar__img"
              />
            </div>
            <div className="menu-header__texts">
              <span className="menu-header__texts__name">
                {firstName(name)}
              </span>
              <a className="menu-header__texts__profile" onClick={profileEdit}>
                Editar Perfil
              </a>
            </div>
          </div>
          <div>
            <img
              className="menu-header__close"
              src={icons.close.src}
              alt={icons.close.alt}
              onClick={onClose}
            ></img>
          </div>
        </div>
        <nav className="menu-header__body">
          {itensMenu.map(
            ({ label, icon, slug, title }: item, index: number) => {
              return (
                <div
                  key={index}
                  className={`menu-header__nav ${
                    slug === location ? 'active' : ''
                  }`}
                  onClick={() => navClick(slug, title)}
                >
                  <div className="menu-header__nav__item">
                    <div className="menu-header__nav__item__left">
                      <img
                        className="menu-header__nav__item__icon"
                        src={icon}
                      ></img>
                      <div className="menu-header__nav__item__title">
                        {label}
                      </div>
                    </div>
                    <Conditional
                      condition={title === 'Central de Monitoramento'}
                      renderIf={<NewFeatureTag textTag="Novidade!" />}
                    />
                    <img src={rightIcon}></img>
                  </div>
                </div>
              );
            }
          )}

          <AppReview className="menu-header__app-review" />

          <div className="menu-header__exit-button">
            <Button
              color="default"
              disabled={false}
              expand="x"
              iconRight
              justify="center"
              loading={false}
              size="medium"
              type="secondary"
              onClick={() => navClick(RouteMap.Logout, 'Sair')}
            >
              Sair
            </Button>
          </div>
        </nav>
      </div>
    </div>
  );
};
