import {
  IconGift,
  IconPercent,
  IconNegotiation,
  IconCreditCard,
  IconCalendar,
} from '@consumidor-positivo/aurora';

export const icons = {
  cpPontuacao: <IconGift color="dark" />,
  cpDesconto: <IconPercent color="dark" />,
  cpParceiros: <IconNegotiation color="dark" />,
  cpContactless: <IconCreditCard color="dark" />,
  cpAnuidade: <IconCalendar color="dark" />,
};

// TODO: temp solution for AB-TEST
// If the variant wins, we should improve this solution
export const partnerFriendlyName = {
  itau: 'Itaú',
  santander: 'Santander',
};
