import Denakop from '@common/components/Denakop';
import { Footer } from '@common/components/Footer';
import { LoggedInHeader } from '@common/components/LoggedInHeader';
import { LoggedOutHeader } from '@common/components/LoggedOutHeader';
import { RouteMap } from '@common/constants/RouteMap';
import { useUserContext } from '@common/contexts/UserContext';
import { useSortingHat } from '@common/hooks/useSortingHat';
import isMobile from '@libs/utils/helpers/isMobile';
import { Conditional } from 'iq-blueberry';
import { useEffect } from 'react';

import 'iq-blueberry/src/core/components/legacy/FooterClassic.styl';
import './styles.scss';
interface IPrivateDefaultProps {
  children: React.ReactNode;
}

export const PrivateDefaultTemplate: React.FC<IPrivateDefaultProps> = ({
  children,
}) => {
  const { user, getUser } = useUserContext();
  useSortingHat({ immediate: true, decideDelayInSeconds: 2 });

  useEffect(() => {
    getUser();
  }, [user]);

  return (
    <>
      <Conditional
        condition={!!user}
        renderIf={<LoggedInHeader username={user?.full_name} />}
        renderElse={<LoggedOutHeader />}
      />

      <main className="private-default-template">{children}</main>
      <Footer />

      <Conditional
        condition={!!isMobile() && location.pathname === RouteMap.Objectives}
        renderIf={<Denakop page="meus-objetivos-mobile" id="1" />}
      />
      <Conditional
        condition={!!isMobile() && location.pathname === RouteMap.MyDebts}
        renderIf={<Denakop page="minhas-dividas-mobile" id="1" />}
      />
    </>
  );
};
