import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';
import { useLocation, useNavigate } from 'react-router-dom';

import { delay } from '@libs/utils/helpers/delay';
import { isIOS } from '@libs/utils/helpers/mobileOS';
import { useStoryblokDatasource } from '@libs/storyblok';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { getSiteIdParam } from '@libs/utils/helpers/getSiteIdParam';
import { getDeliveryIdParam } from '@libs/utils/helpers/getDeliveryIdParam';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';
import {
  addAutofillParam,
  FireboltUserInfo,
} from '@libs/utils/helpers/addAutofillParam';

import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';
import { SHDecisionItem } from '@common/hooks/useSortingHat';
import { useUserContext } from '@common/contexts/UserContext';
import {
  isUserFromWebView,
  redirectInApp,
} from '@common/utils/webviewComunication';

import { useApiFirebolt } from '@hub/apis/firebolt/useApiFirebolt';

export const useHubRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    productClickedEvent,
    redirectRequestedEvent,
    proposalStartedEvent,
    elementClickedEvent,
  } = useTracking();
  const { sendSoftProposal } = useApiFirebolt();
  const { user } = useUserContext();
  const [cookies] = useCookies(['app_version']);
  const { getDatasource } = useStoryblokDatasource();

  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const [redirectModal, setRedirectModal] = useState<{
    isOpen: boolean;
    name: string;
    image: string;
  }>({ isOpen: false, name: '', image: '' });

  type HandleOfferRedirectParams = {
    offer: SHDecisionItem;
    position: number;
    location?: string;
  };
  async function handleOfferRedirect({
    offer,
    position,
    location,
  }: HandleOfferRedirectParams) {
    setLoadingRedirect(true);

    const safeLocation = location || pathname;
    const offerPartner = offer?.generalInfo?.partner;
    const isACOffer = offerPartner === 'acordocerto';

    const productClickedEventPayload = {
      product: {
        brand: offerPartner,
        category: offer?.generalInfo?.category,
        location: safeLocation,
        name: offer?.content?.name,
        position,
        productId: offer?.content?.slug,
        text: offer?.content?.title,
        variant: offer?.content?.variant,
      },
    };

    if (isACOffer) {
      productClickedEvent({
        ...productClickedEventPayload,
        url: RouteMap.MyDebts,
      });

      elementClickedEvent({
        elementType: 'button',
        location: pathname,
        name: 'Modal AC SH - Conferir se tenho dívidas',
      });
      return navigate(RouteMap.MyDebts);
    }

    setRedirectModal({
      isOpen: true,
      name: offerPartner,
      image: offer?.content.image_logo.filename,
    });

    const proposal = await getProposalFromFireboltSoft({ offer });
    const fireboltId = proposal?.firebolt_id || '';

    let url = await getUrl(offer, fireboltId);

    if (!url) return;

    if (offer?.content.full_funnel) {
      const userInfo: FireboltUserInfo = {
        cpf: user?.document,
        fullName: user?.full_name,
        email: user?.email,
        phone: user?.phone,
      };

      url = addAutofillParam({
        productSlug: offer?.content.slug,
        url,
        user: userInfo,
      });
    }

    redirectRequestedEvent({
      fireboltId,
      offerSlug: offer?.content?.slug,
      originPath: pathname,
      redirectURL: url,
    });

    productClickedEvent({ ...productClickedEventPayload, url });
    elementClickedEvent({
      elementType: 'button',
      location: pathname,
      name: `${location} - ${offer?.content?.button_text || ''}`,
    });
    await sendEventProposalStarted(offer, url, fireboltId);
    await delay(2000);
    redirect(url);
    setRedirectModal({ isOpen: false, name: '', image: '' });
    setLoadingRedirect(false);
  }

  async function getUrl(offer: SHDecisionItem, fireboltId: string) {
    const links = offer?.content?.links;
    const preApproved = offer?.generalInfo?.preApproved || false;

    let url = getStoryblokUrl(links, preApproved);

    if (url) {
      const completedUrl = await fillUrlParameters(url, fireboltId);
      return completedUrl;
    }
  }

  function getStoryblokUrl(links: any, isPreApproved: boolean) {
    const linksCP = links.find(
      (link: any) => link.application === 'cp-logged-links'
    );

    if (!linksCP) return;

    const link = isPreApproved
      ? linksCP?.link_pre_approved
      : linksCP?.link_without_debts;

    return link.url;
  }

  async function fillUrlParameters(url: string, fireboltId: string) {
    const startSourceUrl = getCookie('start_source_url') || '';
    const params = {
      fireboltId,
      sessionId: getSessionID(),
      deliveryId: getDeliveryIdParam({ startSourceUrl }),
      siteId:
        (await getSiteIdParam({
          marketplace: 'cp',
          startSourceUrl,
          getDatasource,
        })) || '',
    };
    const completedUrl = url
      .replace('(session_id)', getSessionID())
      .replace('(firebolt_id)', params.fireboltId)
      .replace('(delivery_id)', params.deliveryId)
      .replace('(af_siteid)', params.siteId);

    return completedUrl;
  }

  type FireboltSkipperParams = {
    offer: SHDecisionItem;
    additionalItem?: any;
    additionalMetadata?: any;
  };
  async function getProposalFromFireboltSoft({
    offer,
    additionalItem = {},
    additionalMetadata = {},
  }: FireboltSkipperParams) {
    if (!offer) return null;

    const isBBCard = offer?.content?.partner === 'bancodobrasil';
    if (isBBCard) return null;

    const item = {
      cpf: user?.document,
      full_name: user?.full_name,
      email: user?.email,
      main_phone: user?.phone,
      date_of_birth: user?.birthdate,
      choosen_card: offer?.content?.slug,
      issuer: offer?.generalInfo?.partner,
      ...additionalItem,
    };

    const metadata = {
      start_source_url: getCookie('start_source_url'),
      user_agent: window.navigator.userAgent,
      anonymousId: getAnonymousID(),
      sessionId: getSessionID(),
      fbCookies: {
        fcbId: getFbAvailableCookies().fbc,
        fbpId: getFbAvailableCookies().fbp,
      },
      gaCookies: {
        gaId: getGAAvailableCookies().ga,
      },
      ...additionalMetadata,
    };
    const proposalResponse = await sendSoftProposal.send(
      offer?.generalInfo?.partner,
      {
        item,
        metadata,
      }
    );

    return proposalResponse;
  }

  async function sendEventProposalStarted(
    offer: SHDecisionItem,
    redirectUrl: string,
    fireboltId: string
  ) {
    if (!offer) return;

    if (offer?.content?.full_funnel) return;

    const payload = {
      initialSlug: offer?.content?.slug,
      finalSlug: offer?.content?.slug,
      category: offer?.generalInfo?.category,
      redirectUrl,
      fireboltId,
    };
    await proposalStartedEvent(payload);
  }

  /**
   * Redirects the user to the given URL
   * For some reason, in Android the "window.location.href" doesn't work :(
   * And, for some another reason, in iOS the "window.open" doesn't work :(
   * For this reason, responsibility for redirection has been assigned to React Native in apps updated from version 5.0.3
   *
   * So, this function is needed to make it work in both platforms
   *
   * Feel free to improve this function!! (if you can test on both platforms, in more than 1 device!!)
   */
  function redirect(url: string) {
    if (isUserFromWebView() && cookies.app_version) return redirectInApp(url);
    if (isIOS()) return window.open(url, '_top');
    return window.open(url, '_blank');
  }

  return {
    loadingRedirect,
    redirectModal,
    handleOfferRedirect,
  };
};
